@import '../../../bpp-variables';

.setup-release {
  margin: 20px auto 0px auto;
  width: 100%;
  text-align: center;

  .modal-title {
    text-align: left;
  }

  .setup-release-instructions {
    margin: 15px 0 15px 0;
  }

  .setup-release-container {
    display: inline-block;
    vertical-align: top;
    min-width: 150px;

    .setup-release-heading {
      padding-bottom: 6px;
      font-weight: 600;
      min-width: 50px;
      text-align: center;
    }

    .setup-release-button {
      width: 127px;
      border: 1px solid #000 !important;
      text-align: center;
      border-radius: 10px;
      color: #000000;
      font-size: 24px;
      height: 140px;
      padding: 0px;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
    }

    .setup-release-button:hover {
      border-color: #32a1d9 !important;
      color: #32a1d9;
    }

    .setup-release-button:active {
      border-color: #018abe;
      color: #018abe;
    }
  }

  .setup-release-comment-container {
    display: inline-block;
    width: 64%;
    position: relative;

    .setup-release-comment-group {
      display: inline-block;
      width: 80%;

      .setup-release-comment {
        border-radius: 10px;
        height: 140px;
        width: 100%;
        padding: 5px 0 0 10px;
      }

      .setup-release-comment-heading {
        min-width: 189px;
        font-weight: 600;
        margin: 0 auto;
        padding-bottom: 6px;
      }

      .setup-release-comment-readonly {
        background-color: #eeeeee;
      }
    }

    .setup-release-comment-button-group {
      display: inline-block;
      width: 10%;
      position: relative;
      bottom: 14px;

      .setup-release-comment-button-panel {
        width: 92px;
        height: 25px;

        .setup-release-comment-cancel-button {
          cursor: pointer;
        }

        .setup-release-comment-approve-button {
          cursor: pointer;
        }
      }
    }

    .hide-group {
      display: none;
    }
  }

  .disabled-text {
    color: #81848c;
    pointer-events: none;

    .setup-release,
    .setup-release:hover {
      border: 1px solid #81848c;
      color: #81848c;
    }
  }

  .fileUpload-content {
    padding: 15px;
  }

  .font-size-reg {
    font-size: $bpp-font-size-reg;
  }

  .font-size-lg {
    font-size: 34px;
  }

  .right-bottom-img {
    position: absolute;
    bottom: 6px;
    right: 14px;
  }

  .setup-preview {
    border-radius: 10px;
    height: 140px;
    width: 127px;
    cursor: pointer;
  }

  .accepted-text {
    color: #43a7b3;
    font-size: 13px;
  }

  .rejected-text {
    font-size: 22px;
  }

  .fa.fa-trash-o.rejected-text.font-size-lg:hover {
    font-size: 22px;
  }

  .button-panel {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;

    button {
      width: 150px;
      margin: 10px 10px 0 10px;
    }
  }
}

.ifs-container {
  &__description {
    margin: 10px 0 10px 0;
  }
}
